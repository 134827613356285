var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BT-Blade-Items',{attrs:{"addBladeName":"measurement","bladesData":_vm.bladesData,"bladeName":"measurements","canAdd":"","canDelete":"","canSearchLocal":"","headers":[
        { text: 'Name', value: 'measurementName', title: 1, searchable: true },
        { text: 'Abbreviation', value: 'abbreviation', subtitle: 2, prefix: 'Abb: ', searchable: true },
        { text: 'Standard', value: 'measurementStandard', searchable: true },
        { text: 'Tags', value: 'tags', display: true, subtitle: 2, prefix: 'Tags: ', searchable: true }],"navigation":"measurements","title":"Measurements"},scopedSlots:_vm._u([{key:"tags",fn:function(ref){
        var item = ref.item;
return [_c('BT-Tag-Chips',{model:{value:(item.tags),callback:function ($$v) {_vm.$set(item, "tags", $$v)},expression:"item.tags"}})]}},{key:"itemActions",fn:function(ref){
        var data = ref.data;
        var item = ref.item;
return [(data.openBlade != null)?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return data.openBlade({ bladeName: 'stock-increments', data: { measurementID: item.id } })}}},[_c('v-icon',[_vm._v("mdi-counter")])],1):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }