<template>
    <BT-Blade-Items
        addBladeName="measurement"
        :bladesData="bladesData"
        bladeName="measurements"
        canAdd
        canDelete
        canSearchLocal
        :headers="[
            { text: 'Name', value: 'measurementName', title: 1, searchable: true },
            { text: 'Abbreviation', value: 'abbreviation', subtitle: 2, prefix: 'Abb: ', searchable: true },
            { text: 'Standard', value: 'measurementStandard', searchable: true },
            { text: 'Tags', value: 'tags', display: true, subtitle: 2, prefix: 'Tags: ', searchable: true }]"
        navigation="measurements"
        title="Measurements">
        <template v-slot:tags="{ item }">
            <BT-Tag-Chips v-model="item.tags" />
        </template>
        <template v-slot:itemActions="{ data, item }">
            <v-btn
                v-if="data.openBlade != null"
                @click.stop="data.openBlade({ bladeName: 'stock-increments', data: { measurementID: item.id } })"
                icon
                small>
                <v-icon>mdi-counter</v-icon>
            </v-btn>
        </template>
    </BT-Blade-Items>
</template>

<script>
export default {
    name: 'Measurements-Blade',
    components: {
        BTTagChips: () => import('~components/BT-Tag-Chips.vue')
    },
    props: {
        bladesData: null
    }
}
</script>